<template>
    <div>
        <div class="custom-search d-flex justify-content-end">
            <div v-if="$acl.canCreatePermission(role)" class="float-left mr-auto">
                <b-button
                        variant="primary"
                        @click="newEntity"
                >
                    <span class="text-nowrap">Hozzáadás</span>
                </b-button>
            </div>
            <b-form-group>
                <div class="d-flex align-items-center">
                    <label class="mr-1">Keresés</label>
                    <b-form-input
                            v-model="searchTerm"
                            placeholder=" ..."
                            type="text"
                            class="d-inline-block"
                            @input="globalSearch"
                    />
                </div>
            </b-form-group>
        </div>

        <!-- table -->
        <vue-good-table
                mode="remote"
                :columns="columns"
                :rows="rows"
                :rtl="direction"
                :pagination-options="{
          enabled: true,
          perPage: pageLength
        }"
                :sort-options="{
          enabled: true,
          initialSortBy: sortBy
        }"
                @on-row-click="onRowClick"
                @on-sort-change="onSortChange"
                @on-column-filter="onColumnFilter"
        >
            <div slot="emptystate">
                <div class="vgt-center-align vgt-text-disabled">
                    {{ $t('table.list.empty') }}
                </div>
            </div>
            <template
                    slot="table-row"
                    slot-scope="props"
            >

                <!-- Column: Name -->
                <span
                        v-if="props.column.field === 'name'"
                        class="text-nowrap"
                >
                <span class="text-nowrap">{{ props.row.name }}</span>
              </span>

                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'action'">
                <span v-if="$acl.canEditPermission(role)">
                  <b-button
                          size="sm"
                          variant="primary"
                          class="ml-1"
                          @click.stop="$router.push({ name: 'modify-partner', params: { id: props.row.id }})"
                  >
                    <feather-icon
                            icon="EditIcon"
                    />
                  </b-button>
                </span>
                <span v-if="$acl.canDeletePermission(role)">
                  <b-button
                          size="sm"
                          variant="danger"
                          class="ml-1"
                          @click.stop="deleteEntity(props.row.id, $event)"
                  >
                    <feather-icon
                            icon="XCircleIcon"
                    />
                  </b-button>
                </span>
              </span>

                <!-- Column: Common -->
                <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template
                    slot="pagination-bottom"
                    slot-scope="props"
            >
                <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              {{ ((page - 1) * pageLength) + 1 }} +
            </span>
                        <b-form-select
                                v-model="pageLength"
                                :options="pages"
                                class="mx-1"
                                @change="handlePageChange"
                        />
                        <span class="text-nowrap "> a {{ dataFromServer.total }} találatból </span>
                    </div>
                    <div>
                        <b-pagination
                                :value="1"
                                :total-rows="dataFromServer.total"
                                :per-page="dataFromServer.per_page"
                                first-number
                                last-number
                                align="right"
                                prev-class="prev-item"
                                next-class="next-item"
                                class="mt-1 mb-0"
                                @change="handleChangePage"
                        >
                            <template #prev-text>
                                <feather-icon
                                        icon="ChevronLeftIcon"
                                        size="18"
                                />
                            </template>
                            <template #next-text>
                                <feather-icon
                                        icon="ChevronRightIcon"
                                        size="18"
                                />
                            </template>
                        </b-pagination>
                    </div>
                </div>
            </template>
        </vue-good-table>
    </div>
</template>

<script>
import {
    BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BButton,
} from 'bootstrap-vue'
import {VueGoodTable} from 'vue-good-table'
import store from '@/store'

export default {
    components: {
        VueGoodTable,
        BAvatar,
        BBadge,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdown,
        BDropdownItem,
        BButton,
    },
    data() {
        return {
            role: 'partner',
            pageLength: 10,
            dir: false,
            pages: ['10', '20', '50'],
            columns: [
                {
                    label: this.$t('form.id'),
                    field: 'id',
                    sortable: false,
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Azon. szerinti szűrés',
                    },
                    width: '5%',
                },
                {
                    label: this.$t('form.name'),
                    field: 'name',
                    sortable: true,
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Név szerinti szűrés',
                    },
                },
                {
                    label: 'Link',
                    field: 'url',
                    sortable: true,
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Link szerinti szűrés',
                    },
                },
            ],
            rows: [],
            page: 1,
            dataFromServer: [],
            filterData: [],
            searchTerm: '',
            sortBy: {
                field: 'name',
                type: 'asc',
            },
            columnFilters: [],
        }
    },
    computed: {
        direction() {
            if (store.state.appConfig.isRTL) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.dir = true
                return this.dir
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.dir = false
            return this.dir
        },
    },
    created() {
        let storedPageLength = localStorage.getItem('pageLength')
        if (storedPageLength) {
            this.pageLength = storedPageLength
        }

        if (this.$acl.canEditPermission(this.role) || this.$acl.canDeletePermission(this.role)) {
            const actions = [
                {
                    label: this.$t('table.header.actions'),
                    field: 'action',
                    sortable: false,
                    width: '15rem',
                },
            ]

            this.columns = [...this.columns, ...actions]
        }

        this.initEntities()
    },
    methods: {
        initEntities() {
            const searchParams = {
                page: parseInt(this.page),
                limit: parseInt(this.pageLength),
                query: this.searchTerm,
                columnFilters: this.columnFilters,
                sort: this.sortBy,
            }

            this.$store.dispatch('fetchPartners', searchParams).then(response => {
                this.rows = response.data.entities.data
                this.dataFromServer = response.data.entities
                this.page = this.dataFromServer.current_page
                this.pageLength = parseInt(this.dataFromServer.per_page)
            })
        },
        onRowClick(params) {
            if (this.$acl.canEditPermission(this.role))
                this.$router.push({name: 'modify-partner', params: {id: params.row.id}})
        },
        handleChangePage(page) {
            this.page = page
            this.initEntities()
        },
        handlePageChange(active) {
            this.pageLength = active
            localStorage.setItem('pageLength', active)
            this.page = 1
            this.handleChangePage()
        },
        newEntity() {
            this.$router.push({name: 'add-partner'})
        },
        deleteEntity(id, event) {

            this.$bvModal
                .msgBoxConfirm('Biztosan törölni szeretnéd?', {
                    title: 'Jóváhagyás',
                    size: 'sm',
                    okVariant: 'primary',
                    okTitle: 'Igen',
                    cancelTitle: 'Nem',
                    cancelVariant: 'outline-secondary',
                    hideHeaderClose: false,
                    centered: true,
                })
                .then(value => {
                    if (value) {
                        // axios
                        this.$store.dispatch('deletePartner', id).then(() => {
                            this.initEntities(1)
                            this.$helpers.showSuccessToast(`Sikeres törlés (${id})`)
                        })
                    }
                })
        },
        globalSearch(params) {
            this.page = 1
            this.query = params.searchTerm
            this.initEntities()
        },
        onSortChange(params) {
            this.page = 1
            this.sortBy = params[0]
            this.initEntities()
        },
        onColumnFilter(params) {
            this.page = 1
            this.columnFilters = params.columnFilters
            this.initEntities()
        },
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
